import {GridType} from '../../../../types/galleryTypes';
import {useMemo} from 'react';
import {useStyles} from '@wix/tpa-settings/react';
import {useStylesParams} from '../../../../stylesParamsContext';
import {SliderArrowsPlacement} from '../../../../constants';
import {useGlobals} from '../../../../globalPropsContext';

export const useSliderConfiguration = (sliderWidth: number) => {
  const styles = useStyles();
  const stylesParams = useStylesParams();
  const {totalProducts} = useGlobals();

  const autoGrid = styles.get(stylesParams.gallery_gridType) === GridType.AUTO;
  const minProductWidth = styles.get(stylesParams.gallery_productSize);
  const galleryColumns = styles.get(stylesParams.galleryColumns);
  const shouldShowTeaser = styles.get(stylesParams.gallery_sliderShowTeaser) && totalProducts > galleryColumns;
  const arrowsInsideSlider = styles.get(stylesParams.gallery_sliderArrowsPlacement) === SliderArrowsPlacement.Inside;

  const slidesOffset = arrowsInsideSlider && shouldShowTeaser ? styles.get(stylesParams.gallery_sliderTeaserOffset) : 0;

  const spaceBetween = autoGrid
    ? styles.get(stylesParams.gallery_gapSizeColumn)
    : styles.get(stylesParams.galleryMarginColumn) * 2;

  const calculateResponsiveSlidesCount = () => {
    const availableSliderSpace = sliderWidth + spaceBetween;
    const slideSpace = minProductWidth + spaceBetween;
    const teaserSpace = shouldShowTeaser ? minProductWidth * 0.7 : 0;

    const minSpaceForAllSlides = totalProducts * slideSpace;
    if (availableSliderSpace + teaserSpace > minSpaceForAllSlides) {
      return availableSliderSpace / slideSpace;
    }

    const slidesCountWithTeaser = Math.floor((availableSliderSpace + teaserSpace) / slideSpace);
    const slideWidth = (availableSliderSpace + teaserSpace) / slidesCountWithTeaser;
    return availableSliderSpace / slideWidth;
  };

  const slidesCount = useMemo(() => {
    if (!autoGrid) {
      return shouldShowTeaser ? galleryColumns + 0.4 : galleryColumns;
    }

    if (!sliderWidth) {
      return;
    }

    const responsiveSlidesCount = calculateResponsiveSlidesCount();
    return Math.max(Math.min(responsiveSlidesCount, totalProducts), 1);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [autoGrid, shouldShowTeaser, sliderWidth, minProductWidth, slidesOffset, spaceBetween, galleryColumns]);

  return {slidesCount, spaceBetween, slidesOffset};
};
